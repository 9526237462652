import { createSlice } from "@reduxjs/toolkit"

export const PageSlice = createSlice({
  name: "pageSlice",
  initialState: {
    pages: [],
    item: [],
    userCart: [],
    Id: "",
    total: "",
  },
  reducers: {
    setPage: (state, action) => {
    
      state.pages = Object.assign({}, action.payload.childJson)
      const filteredPrice = Object.values(state.pages).filter(
        key => key.id === "12605"
      )


      let cart = {
        name: action.payload.name,
        title: action.payload.title,
        sku: state?.pages[0]?.content,
        code: state?.pages[2]?.content,
        price:
          filteredPrice && filteredPrice.length > 0
            ? filteredPrice[0]?.content?.slice(1)
            : 0,
        currency: state?.pages[16]?.content?.slice(1, 2),
        quantity: 1,
        color: state?.pages[5]?.content,
        image: action.payload.image,
        url: action.payload.pageUrl,
        shipping: 50,
      }
      let check = state.item?.find(page => page.sku === cart.sku)

      if (!check) {
        state.item = [...state.item, cart]
      }
    },
    increase: (state, action) => {
      const cartItem = state.userCart?.find(
        item => item.name === action.payload
      )
      cartItem.quantity = cartItem.quantity + 1
    },
    decrease: (state, action) => {
      const cartItem = state.userCart?.find(
        item => item.name === action.payload
      )
      cartItem.quantity = cartItem.quantity - 1
    },
    removeItem: (state, action) => {
      const itemName = action.payload
      state.userCart = state.userCart.filter(item => item.name !== itemName)
    },

    clearCart: state => {
      state.item = []
      state.userCart = []
    },

    calculateTotals: state => {
      let shipping = 0
      let total = 0
      state.userCart.forEach(item => {
        if (parseFloat(item.price)) {
          total += item.quantity * parseFloat(item.price)
          shipping += item.quantity * parseFloat(item.shipping)
        }
      })
      // state.amount = amount
      state.total = parseInt(total?.toFixed(2)) + parseInt(shipping?.toFixed(2))
    },
    setUserCart: (state, action) => {
      let findItem = state.item.find(item => item.name === action.payload)
      let check = state.userCart.find(item => item.name === findItem.name)
      if (!check) {
        state.userCart = [...state.userCart, findItem]
      }
    },
    setId: (state, action) => {
      state.Id = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setPage,
  increase,
  decrease,
  removeItem,
  setUserCart,
  clearCart,
  setId,
  calculateTotals,
} = PageSlice.actions

export default PageSlice.reducer
