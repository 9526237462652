import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
// import { Adapter } from "./src/components/Builder/Components/LoginAdapter/Adapter"
const React = require("react")


export const wrapRootElement = ({ element}) => {
  let persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
       {element}
      </PersistGate>
    </Provider>
  )
}


